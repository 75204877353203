<template>
    <div>
        <div class="form-group floating-input mt-3 input-group">
            <input type="text" class="form-control" id="emailField" v-model.trim="$v.changedEmail.$model" :placeholder="$t('email')" @keyup="emitIfValid()" required>
            <label for="emailField">{{$t('email')}}</label>
        </div>
        <div class="validation-error">
            <label v-if="!$v.changedEmail.required && $v.changedEmail.$dirty">{{$t('please-enter-your', { field: $t('email') })}}</label>
            <label v-else-if="!$v.changedEmail.email && $v.changedEmail.$dirty">{{$t('invalid-field', {field: $t('email')} ) }}</label>
        </div>

        <p class="alert alert-danger mt-3 text-center" v-if="accountInvalid">{{$t('account-invalid-email')}}</p>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate';
    import {required, email} from 'vuelidate/lib/validators';

    export default {
        name: 'EmailField',
        props:{
            email: String,
            accountInvalid: String,
        },
        data(){
            return{
                changedEmail: this.email
            }
        },
        mixins: [validationMixin],
        validations() {
            return {
                changedEmail:{
                    required,
                    email
                }
            }
        },
        methods:{
            emitIfValid(){
                this.$v.$touch();
                if(this.$v.changedEmail.$error === false){
                    this.$emit('playerEmail', this.changedEmail);
                } else {
                    this.$emit('playerEmail', null);
                }
            },
        }
    }
</script>

<style scoped></style>
